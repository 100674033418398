// UI states...
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

// Data states
export const UPDATE_SELECTED_CAMPAIGN = "UPDATE_SELECTED_CAMPAIGN";

export const UPDATE_REWARDS = "UPDATE_REWARDS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_CAMPAIGN_TITLE = "UPDATE_CAMPAIGN_TITLE";
export const UPDATE_CAMPAIGN_LINK = "UPDATE_CAMPAIGN_LINK";
export const UPDATE_CAMPAIGN_RULE = "UPDATE_CAMPAIGN_RULE";
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
export const UPDATE_SELECTED_REWARD = "UPDATE_SELECTED_REWARD";

//form validations
export const SET_CAMPAIGN_NAME_VALIDATION_STAT =
  "SET_CAMPAIGN_NAME_VALIDATION_STAT";
export const SET_CAMPAIGN_SLUG_VALIDATION_STAT =
  "SET_CAMPAIGN_SLUG_VALIDATION_STAT";
export const SET_CAMPAIGN_COUPON_COUNT_VALIDATION_STAT =
  "SET_CAMPAIGN_COUPON_COUNT_VALIDATION_STAT";
export const SET_CAMPAIGN_COUPON_TYPE_VALIDATION_STAT =
  "SET_CAMPAIGN_COUPON_TYPE_VALIDATION_STAT";
export const SET_CAMPAIGN_START_DATE_VALIDATION_STAT =
  "SET_CAMPAIGN_START_DATE_VALIDATION_STAT";
export const SET_CAMPAIGN_END_DATE_VALIDATION_STAT =
  "SET_CAMPAIGN_END_DATE_VALIDATION_STAT";
export const SET_CAMPAIGN_VALID_FROM_VALIDATION_STAT =
  "SET_CAMPAIGN_VALID_FROM_VALIDATION_STAT";
export const SET_CAMPAIGN_VALID_TILL_VALIDATION_STAT =
  "SET_CAMPAIGN_VALID_TILL_VALIDATION_STAT";
export const SET_CAMPAIGN_DATE_RANGE_VALIDATION_STAT =
  "SET_CAMPAIGN_DATE_RANGE_VALIDATION_STAT";
export const SET_REWARD_TITLE_VALIDATION_STAT =
  "SET_REWARD_TITLE_VALIDATION_STAT";

export const SET_REWARD_BRIEF_VALIDATION_STAT =
  "SET_REWARD_BRIEF_VALIDATION_STAT";

// Top nav filter actions
export const SET_CAMPAIGN_TYPE_FILTER = "SET_CAMPAIGN_TYPE_FILTER";
export const SET_CAMPAIGN_STATUS_FILTER = "SET_CAMPAIGN_STATUS_FILTER";
export const SET_REPORT_FILTER_DATE_TO = "SET_REPORT_FILTER_DATE_TO";
export const SET_REPORT_FILTER_DATE_FROM = "SET_REPORT_FILTER_DATE_FROM";
