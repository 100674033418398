import React, { Component } from "react";
import { connect } from "react-redux";

import Aux from "../../merchant_partner_dashboard/hoc/_Aux";
import * as actionTypes from "../../merchant_partner_dashboard/store/actions";
import DEMO from "../../merchant_partner_dashboard/store/constant";

import { Dropdown } from "react-bootstrap";
import OfferxpApiClient from "../services/api-services";
import CookieService from "../services/cookie-service";
import config from "../../merchant_partner_dashboard/config";
import {
  getActiveStoreFromLocalStorage,
  getMerchantDataFromLocalStorage,
} from "../../common/oxp_utils/common_utils";
import ProfileAvatar from "../assets/images/user/profile_avatar.png";

class LoggedOutTopNav extends Component {
  handleLogout = () => {
    const apiClient = new OfferxpApiClient();
    apiClient.userLogout().then((_) => {
      CookieService.deleteToken();
      localStorage.removeItem(config.keyToken);
      let role = localStorage.getItem("role");
      if (role) {
      }
      localStorage.clear();
      window.location = config.authPage;
    });
  };

  render() {
    let headerClass = [
      "navbar",
      "pcoded-header",
      "navbar-expand-lg",
      "m-l-0",
      "w-100",
      "logged-out-nav",
    ];
    if (this.props.headerFixedLayout) {
      headerClass = [...headerClass, "headerpos-fixed"];
    }

    let toggleClass = ["mobile-menu"];
    if (this.props.collapseMenu) {
      toggleClass = [...toggleClass, "on"];
    }

    if (this.props.noLogOutNeeded) {
      return (
        <Aux>
          <header className={headerClass.join(" ")} style={{ marginLeft: "0" }}>
            <a href="#" className="b-brand">
              <div>
                <i className="feather icon-trending-up" />
              </div>
              <span className="b-title">OfferXP</span>
            </a>
          </header>
        </Aux>
      );
    }
    let merchantName = getMerchantDataFromLocalStorage()
      ? JSON.parse(getMerchantDataFromLocalStorage())
      : { username: "Merchant" };
    return (
      <Aux>
        <header className={headerClass.join(" ")} style={{ marginLeft: "0" }}>
          <a className="mobile-menu" id="mobile-header" href="#">
            <i className="feather icon-more-horizontal" />
          </a>
          <div className="collapse navbar-collapse">
            <Dropdown
              alignRight={!this.props.rtlLayout}
              className="drp-user drp-user-logged-out"
            >
              <Dropdown.Toggle variant={"link"} id="dropdown-basic">
                <i
                  className="icon feather icon-settings"
                  style={{color: "#444"}}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="profile-notification">
                <div className="pro-head">
                  <img
                    src={ProfileAvatar}
                    className="img-radius"
                    alt="User Profile"
                  />
                  <span className="text-capitalize">
                    {merchantName.username}
                  </span>
                  <small className="d-block">{merchantName.email}</small>
                  {/* {getActiveStoreFromLocalStorage() ? (
                    <p className="d-block">
                      <small>{getActiveStoreFromLocalStorage()}</small>
                    </p>
                  ) : null} */}
                  <a
                    href={DEMO.BLANK_LINK}
                    className="dud-logout"
                    title="Logout"
                    onClick={this.handleLogout}
                  >
                    <i className="feather icon-log-out" />
                  </a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </header>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rtlLayout: state.rtlLayout,
    headerBackColor: state.headerBackColor,
    headerFixedLayout: state.headerFixedLayout,
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutTopNav);
