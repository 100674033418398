// UI states...
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const RENDER_STICKY_FILTER = "RENDER_STICKY_FILTER";

// Data states
export const UPDATE_SELECTED_CAMPAIGN = "UPDATE_SELECTED_CAMPAIGN";

export const UPDATE_REWARDS = "UPDATE_REWARDS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_CAMPAIGN_TITLE = "UPDATE_CAMPAIGN_TITLE";
export const UPDATE_CAMPAIGN_RULE = "UPDATE_CAMPAIGN_RULE";
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
export const UPDATE_SELECTED_REWARD = "UPDATE_SELECTED_REWARD";

export const UPDATE_SELECTED_CRITERIA = "UPDATE_SELECTED_CRITERIA";
export const UPDATE_SELECTED_ROLE = "UPDATE_SELECTED_ROLE";
export const UPDATE_DASHBOARD_APP = "UPDATE_DASHBOARD_APP";

//form validations
export const SET_CAMPAIGN_NAME_VALIDATION_STAT =
  "SET_CAMPAIGN_NAME_VALIDATION_STAT";
export const SET_CAMPAIGN_SLUG_VALIDATION_STAT =
  "SET_CAMPAIGN_SLUG_VALIDATION_STAT";
export const SET_CAMPAIGN_COUPON_COUNT_VALIDATION_STAT =
  "SET_CAMPAIGN_COUPON_COUNT_VALIDATION_STAT";
export const SET_CAMPAIGN_COUPON_TYPE_VALIDATION_STAT =
  "SET_CAMPAIGN_COUPON_TYPE_VALIDATION_STAT";
export const SET_CAMPAIGN_START_DATE_VALIDATION_STAT =
  "SET_CAMPAIGN_START_DATE_VALIDATION_STAT";
export const SET_CAMPAIGN_END_DATE_VALIDATION_STAT =
  "SET_CAMPAIGN_END_DATE_VALIDATION_STAT";
export const SET_CAMPAIGN_DATE_RANGE_VALIDATION_STAT =
  "SET_CAMPAIGN_DATE_RANGE_VALIDATION_STAT";
export const SET_CAMPAIGN_DATE_NOT_OVER_STAT =
  "SET_CAMPAIGN_DATE_NOT_OVER_STAT";
export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE";
export const SET_DASHBOARD_APP = "SET_DASHBOARD_APP";

// Top nav filter actions
export const SET_CAMPAIGN_TYPE_FILTER = "SET_CAMPAIGN_TYPE_FILTER_RPD";
export const SET_CAMPAIGN_STATUS_FILTER = "SET_CAMPAIGN_STATUS_FILTER_RPD";
export const SET_REPORT_FILTER_DATE_TO = "SET_REPORT_FILTER_DATE_TO_RPD";
export const SET_REPORT_FILTER_DATE_FROM = "SET_REPORT_FILTER_DATE_FROM_RPD";
