const KEY_STORE = "stores";
const KEY_MERCHANT_DATA = "merchantData";
const KEY_ACTIVE_STORE = "active-store";
const KEY_CAMPAIGN_DRAFT = "campaign-draft";
const KEY_ACTIVE_ROLE = "role";

const saveActiveStoreToLocalStorage = (activeStore) => {
  localStorage.setItem(KEY_ACTIVE_STORE, activeStore);
};

const saveActiveRoleToLocalStorage = (activeRole) => {
  localStorage.setItem(KEY_ACTIVE_ROLE, activeRole);
};

const saveStoresToLocalStorage = (stores) => {
  localStorage.setItem(KEY_STORE, JSON.stringify(stores));
};

const saveMerchantDataToLocalStorage = (merchantData) => {
  localStorage.setItem(KEY_MERCHANT_DATA, JSON.stringify(merchantData));
};

const saveCampaignDraftToLocalStorage = (campaignDraft) => {
  localStorage.setItem(KEY_CAMPAIGN_DRAFT, JSON.stringify(campaignDraft));
};

const getMerchantDataFromLocalStorage = () => {
  return localStorage.getItem(KEY_MERCHANT_DATA);
};

const getStoresFromLocalStorage = () => {
  return localStorage.getItem(KEY_STORE);
};

const getActiveStoreFromLocalStorage = () => {
  return localStorage.getItem(KEY_ACTIVE_STORE);
};

const getActiveRoleFromLocalStorage = () => {
  return localStorage.getItem(KEY_ACTIVE_ROLE);
};

const getCampaignDraftFromLocalStorage = () => {
  // since string is stored in localstorage, but on retrieval of the items it will be used as an object and it is more
  // efficient to use an object, so parsing the string here itself before returning.
  return JSON.parse(localStorage.getItem(KEY_CAMPAIGN_DRAFT));
};

const removeCampaignDraftFromLocalStorage = () => {
  localStorage.removeItem(KEY_CAMPAIGN_DRAFT);
};

const removeActiveStoreFromLocalStorage = () => {
  return localStorage.removeItem(KEY_ACTIVE_STORE);
};

const replaceHyphensWithSpace = (inputStr) => {
  return inputStr?.replace(/-/g, " ");
};

const getSelectedReward = (rewardList, selectedRewards) => {
  let selectedReward = rewardList?.filter((reward, index) => {
    return (
      selectedRewards &&
      selectedRewards.length > 0 &&
      selectedRewards[0].reward_campaign === reward.id
    );
  });

  if (selectedReward && selectedReward.length > 0) return selectedReward[0];
  return null;
};

export {
  saveStoresToLocalStorage,
  getStoresFromLocalStorage,
  saveMerchantDataToLocalStorage,
  saveActiveStoreToLocalStorage,
  saveActiveRoleToLocalStorage,
  getMerchantDataFromLocalStorage,
  getActiveStoreFromLocalStorage,
  getActiveRoleFromLocalStorage,
  getCampaignDraftFromLocalStorage,
  saveCampaignDraftToLocalStorage,
  removeCampaignDraftFromLocalStorage,
  removeActiveStoreFromLocalStorage,
  replaceHyphensWithSpace,
  getSelectedReward,
};
