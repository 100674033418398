import { combineReducers } from "redux";
import dataStatesReducer from "../../merchant_partner_dashboard/store/reducers/data-states-reducer";
import uiStatesReducer from "../../merchant_partner_dashboard/store/reducers/ui-states-reducer";
import formValidationReducer from "../../merchant_partner_dashboard/store/reducers/form_validation_reducer";
import rpdDataStateReducer from "../../reward_partner_dashboard/store/reducers/data-states-reducer";

const combinedReducer = combineReducers({
  ui_states: uiStatesReducer,
  data_states: dataStatesReducer,
  form_validation: formValidationReducer,
  rpd_data_states: rpdDataStateReducer,
});

export default combinedReducer;
