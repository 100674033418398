import ShopifyLogo from "./assets/images/widget/shopify.png";
import WebsiteLogo from "./assets/images/widget/internet.png";
import AndroidLogo from "./assets/images/widget/android.png";
import IosLogo from "./assets/images/widget/apple.png";
import DefaultStoreLogo from "./assets/images/widget/campaign.png";

const APP_CONSTANTS = {
  APP_TYPE_MERCHANT_PARTNER: "mpd",
  APP_TYPE_REWARD_PARTNER: "rpd",
};
const MERCHANT_PARTNER_CONSTANTS = {
  REWARD_CONFIG: {
    ALL_ORDERS: "all",
    DELIVERY_DIRECT: "direct",
    DELIVERY_OFFERXP: "offerxp",
    REWARD_DELIVERY_DIRECT: "direct",
    REWARD_DELIVERY_API: "api",
    REWARD_DELIVERY_SHOPIFY: "shopify",
    REWARD_DELIVERY_EMAIL: "email",
  },
  CAMPAIGN_TYPES: {
    TYPE_EARNING: "earning",
    TYPE_PAID: "paid",
    TYPE_FREE: "free",
  },
  REWARD_TYPES: {
    STATUS_ACTIVE: "active",
    STATUS_SOLD: "sold",
    STATUS_UPCOMING: "upcoming",
  },
  REWARD_NOTIFICATION_MODE: {
    EMAIL: "email",
    SMS: "sms",
    SHOPIFY: "shopify",
  },
  REWARD_UNFREEZE_METHOD: {
    ORDER_FULFILLMENT: "order_fulfillment",
    CUSTOM_PERIOD: "custom_period",
  },
  STORE_TYPE_LOGO: {
    shopify: ShopifyLogo,
    website: WebsiteLogo,
    android: AndroidLogo,
    ios: IosLogo,
    default: DefaultStoreLogo,
  },
};

const REWARD_PARTNER_CONSTANTS = {
  REWARD_CODE_TYPE: {
    REWARD_CAMPAIGN_TYPE_MULTI_CODE_SINGLE_USE: "multi-code-single-use",
    REWARD_CAMPAIGN_TYPE_SINGLE_CODE_MULTI_USE: "single-code-multi-use",
    REWARD_CAMPAIGN_TYPE_REWARD_NO_LINK: "no-code",
  },
};

export { APP_CONSTANTS, MERCHANT_PARTNER_CONSTANTS, REWARD_PARTNER_CONSTANTS };
