// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import Logger from "./logger";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

ReactGA.initialize(process.env.REACT_APP_GA_M_ID);
Logger.log("an123", process.env.REACT_APP_GA_M_ID);

export const EVENTS = {
  // Email verification events
  EMAIL_VERIFICATION_SUCCESS: "email-verification-success",
  EMAIL_VERIFICATION_FAILED: "email-verification-failed",
  EMAIL_VERIFICATION_ERROR: "email-verification-error",
  // Password reset events
  PASSWORD_RESET_SUCCESS: "password_reset_success",
  PASSWORD_RESET_FAILED: "password_reset_failed",
  PASSWORD_RESET_ERROR: "password_reset_error",
  PASSWORD_RESET_EMAIL_SENT_SUCCESS: "password_reset_email_sent_success",
  PASSWORD_RESET_EMAIL_SENT_FAILED: "password_reset_email_sent_failed",
  // Login
  DASHBOARD_LOGIN_SUCCESS: "dashboard-login-success",
  DASHBOARD_LOGIN_FAILED: "dashboard-login-failed",
  DASHBOARD_LOGIN_ERROR: "dashboard-login-error",
  DASHBOARD_LOGOUT_SUCCESS: "dashboard-logout-success",
  DASHBOARD_LOGOUT_FAILED: "dashboard-logout-failed",
  DASHBOARD_LOGOUT_ERROR: "dashboard-logout-error",
  // Campaign
  MERCHANT_CAMPAIGN_DISABLED_SUCCESS: "merchant-campaign-disabled-success",
  MERCHANT_CAMPAIGN_DISABLED_FAILED: "merchant-campaign-disabled-failed",
  MERCHANT_CAMPAIGN_DISABLED_ERROR: "merchant-campaign-disabled-error",
  MERCHANT_CAMPAIGN_ENABLED_SUCCESS: "merchant-campaign-enabled-success",
  MERCHANT_CAMPAIGN_ENABLED_FAILED: "merchant-campaign-enabled-failed",
  MERCHANT_CAMPAIGN_ENABLED_ERROR: "merchant-campaign-enabled-error",
  MERCHANT_CAMPAIGN_DELETED_SUCCESS: "merchant-campaign-deleted-success",
  MERCHANT_CAMPAIGN_DELETED_FAILED: "merchant-campaign-deleted-failed",
  MERCHANT_CAMPAIGN_DELETED_ERROR: "merchant-campaign-deleted-error",
  MERCHANT_CAMPAIGN_CREATED_SUCCESS: "merchant-campaign-created-success",
  MERCHANT_CAMPAIGN_CREATED_FAILED: "merchant-campaign-created-failed",
  MERCHANT_CAMPAIGN_CREATED_ERROR: "merchant-campaign-created-error",
  MERCHANT_CAMPAIGN_EDITED_SUCCESS: "merchant-campaign-edited-success",
  MERCHANT_CAMPAIGN_EDITED_FAILED: "merchant-campaign-edited-failed",
  MERCHANT_CAMPAIGN_EDITED_ERROR: "merchant-campaign-edited-error",
  MERCHANT_CAMPAIGN_COUPON_EXPORT_SUCCESS:
    "merchant-campaign-coupon-export-success",
  MERCHANT_CAMPAIGN_COUPON_EXPORT_FAILED:
    "merchant-campaign-coupon-export-failed",
  MERCHANT_CAMPAIGN_COUPON_EXPORT_ERROR:
    "merchant-campaign-coupon-export-error",
  REWARD_DELIVERY_SHOPIFY: "reward_delivery_shopify",
  REWARD_DELIVERY_EMAIL: "reward_delivery_email",
  REWARD_DELIVERY_API: "reward_delivery_api",
  MERCHANT_CAMPAIGN_FREEZE_REWARD_ENABLED_SUCCESS:
    "merchant-campaign-freeze-reward-enabled-success",
  MERCHANT_CAMPAIGN_FREEZE_REWARD_DISABLED_SUCCESS:
    "merchant-campaign-freeze-reward-disables-success",
  MERCHANT_CAMPAIGN_FREEZE_REWARD_DISABLED_FAILED:
    "merchant-campaign-freeze-reward-disabled-failed",
  MERCHANT_CAMPAIGN_FREEZE_REWARD_ENABLED_FAILED:
    "merchant-campaign-freeze-rewards-enabled-failed",
  // Shopify connection / Install
  SHOPIFY_CONNECTION_SUCCESS: "shopify-connection-success",
  SHOPIFY_CONNECTION_FAILED: "shopify-connection-failed",
  SHOPIFY_CONNECTION_ERROR: "shopify-connection-error",
  SHOPIFY_CONNECTION_DISABLED_SUCCESS: "shopify-connection-disabled-success",
  SHOPIFY_CONNECTION_DISABLED_FAILED: "shopify-connection-disabled-failed",
  SHOPIFY_CONNECTION_DISABLED_ERROR: "shopify-connection-disabled-error",

  // Code Quantity EVENTS
  CAMPAIGN_QUANTITY_UPDATE_SUCCESS: "campaign_quantity_update_success",
  CAMPAIGN_QUANTITY_UPDATE_FAILED: "campaign_quantity_update_failed",
  // events with string manipulation
  // Campaign type
  CAMPAIGN_TYPE_: "campaign_type_",

  // Privacy Clicks
  PRIVACY_CLICKS: "privacy_clicks",
};

const pushGAEvent = (eventName, eventData) => {
  ReactGA.event(eventName, {
    event_label: eventData,
  });
};
const pushMixPanelEvent = (eventName, eventData) => {
  mixpanel.track(eventName, eventData);
};

export const setMixPanelUserProfileEvent = (userData) => {
  try {
    mixpanel.identify(userData?.email);
    mixpanel.people.set({
      activeRole: userData?.active_role,
      activeStore: userData?.active_store,
      age: userData?.age,
      email: userData?.email,
      userName: userData?.username,
      onboardingTourCompleted: userData?.onboarding_tour_completed,
      rpdAvailable: userData?.rpd_available,
      pinCode: userData?.pincode,
      contactNumber: userData?.contact_number,
    });
  } catch (error) {
    console.log("mixpanel profile error", error);
  }
};

export const publishEvent = (eventName, eventData) => {
  try {
    pushGAEvent(eventName, eventData);

    pushMixPanelEvent(eventName, eventData);
  } catch (error) {
    console.log("EVENT LOGGING FAILED FOR EVENT ", eventName, eventData);
  }
};
// pre-login actions starts
// login/signup events
export const loginPageNextButtonClickedEvent = (email) => {
  return publishEvent("LOGIN_PAGE_NEXT_BUTTON_CLICKED", { email: email });
};
export const loginPageLoginButtonClickedEvent = (loginSuccessful) => {
  return publishEvent("LOGIN_PAGE_LOGIN_BUTTON_CLICKED", {
    loginSuccessful: loginSuccessful,
  });
};
export const loginPageResendOtpButtonClickedEvent = () => {
  return publishEvent("LOGIN_PAGE_RESEND_OTP_BUTTON_CLICKED", {});
};
export const loginPageUsePasswordButtonClickedEvent = () => {
  return publishEvent("LOGIN_PAGE_USE_PASSWORD_BUTTON_CLICKED", {});
};
export const loginPageUseOtpButtonClickedEvent = () => {
  return publishEvent("LOGIN_PAGE_USE_OTP_BUTTON_CLICKED", {});
};
export const signupPageNextButtonClickedEvent = (email) => {
  return publishEvent("SIGNUP_PAGE_NEXT_BUTTON_CLICKED", { email: email });
};
export const signupPageLoginButtonClickedEvent = () => {
  return publishEvent("SIGNUP_PAGE_LOGIN_BUTTON_CLICKED", {});
};
export const loginPageSignupButtonClickedEvent = () => {
  return publishEvent("LOGIN_PAGE_SIGNUP_BUTTON_CLICKED", {});
};
export const signupPageClosed = () => {
  return publishEvent("SIGNUP_PAGE_CLOSED", {});
};
// pre-login actions ends

// Header and Navigation Actions starts

export const settingsIconClickedEvent = () => {
  return publishEvent("MPD_SETTINGS_ICON_CLICKED", {});
};

export const storeSwitchIconClickedEvent = (eventName, data) => {
  return publishEvent(eventName, data);
};
export const maximizeIconClickedEvent = () => {
  return publishEvent("MPD_MAXIMIZE_ICON_CLICKED", {});
};

export const roleSwitchIconClickedEvent = (data) => {
  return publishEvent("MPD_ROLE_SWITCH_ICON_CLICKED", data);
};
export const settingsPageClickedEvent = () => {
  return publishEvent("MPD_SETTINGS_PAGE_LINK_CLICKED", {});
};
export const logoutIconClickedEvent = () => {
  return publishEvent("MPD_LOGOUT_ICON_CLICKED", {});
};

// Header and Navigation Actions ends

// MPD EVENTS STARTS
export const merchanPartnertLogoClickedEvent = () => {
  return publishEvent("MPD_LOGO_CLICKED", {});
};
export const campaignNavClickedEvent = () => {
  return publishEvent("MPD_CAMPAIGNS_NAV_CLICKED", {});
};

export const reportsNavClickedEvent = () => {
  return publishEvent("MPD_REPORTS_NAV_CLICKED", {});
};
export const shopifyNavClickedEvent = () => {
  return publishEvent("MPD_SHOPIFY_NAV_CLICKED", {});
};

export const manageStoreNavClickedEvent = () => {
  return publishEvent("MPD_MANAGE_STORE_NAV_CLICKED", {});
};

export const activitiesNavClickedEvent = () => {
  return publishEvent("MPD_ACTIVITIES_NAV_CLICKED", {});
};
export const faqNavClickedEvent = () => {
  return publishEvent("MPD_FAQ_NAV_CLICKED", {});
};
export const mailNavClickedEvent = () => {
  return publishEvent("MPD_MAIL_NAV_CLICKED", {});
};
export const whatsAppNavClickedEvent = () => {
  return publishEvent("MPD_WHATSAPP_NAV_CLICKED", {});
};
export const termsNavClickedEvent = () => {
  return publishEvent("MPD_TERMS_NAV_CLICKED", {});
};

export const privacyNavClickedEvent = () => {
  return publishEvent("MPD_PRIVACY_NAV_CLICKED", {});
};

// Campaign Page events
export const campaignCreateClickedEvent = (campaignData) => {
  return publishEvent("MPD_CAMPAIGN_CREATE_CLICKED", campaignData);
};
export const campaignUpdateClickedEvent = (campaignData) => {
  return publishEvent("MPD_CAMPAIGN_UPDATE_CLICKED", campaignData);
};
export const campaignDetailClickedEvent = (campaignData) => {
  return publishEvent("MPD_CAMPAIGN_DETAIL_LINK_CLICKED", {
    campaignData: campaignData,
  });
};

export const listPageCreateCampaignButtonClickedEvent = () => {
  return publishEvent("MPD_LIST_PAGE_CREATE_CAMPAIGN_BUTTON_CLICKED", {});
};
export const campaignCouponExportButtonClickedEvent = () => {
  return publishEvent("MPD_CAMPAIGN_COUPON_EXPORT_BUTTON_CLICKED", {});
};
export const campaignStatusSwitchButtonClickedEvent = (newStatus) => {
  return publishEvent("MPD_CAMPAIGN_STATUS_SWITCH_BUTTON_CLICKED", newStatus);
};

export const campaignFieldChangedEvent = (fieldName, fieldData) => {
  return publishEvent(`MPD_CAMPAIGN_${fieldName}_CHANGED`, fieldData);
};
export const campaignShowAllButtonClickedEvent = (campaignData) => {
  return publishEvent("MPD_CAMPAIGN_SHOW_ALL_CLICKED", campaignData);
};

export const campaignRewardSelectedEvent = (rewardData) => {
  return publishEvent("MPD_CAMPAIGN_REWARD_SELECTED_CLICKED", rewardData);
};
export const campaignRewardDetailsButtonClickedEvent = (rewardData) => {
  return publishEvent("MPD_CAMPAIGN_REWARD_DETAILS_BUTTON_CLICKED", rewardData);
};
export const campaignRewardDetailsPopupClosedEvent = (rewardData) => {
  return publishEvent("MPD_CAMPAIGN_REWARD_POPUP_CLOSED", rewardData);
};
export const campaignRewardTermsButtonClickedEvent = () => {
  return publishEvent("MPD_CAMPAIGN_REWARD_TERMS_BUTTON_CLICKED", {});
};
export const campaignRewardViewWebsiteButtonClickedEvent = (rewardData) => {
  return publishEvent(
    "MPD_CAMPAIGN_REWARD_VIEW_WEBSITE_BUTTON_CLICKED",
    rewardData
  );
};
export const merchantReportsCampaignTypeSelectedEvent = (campaignType) => {
  return publishEvent("MPD_REPORTS_CAMPAIGN_TYPE_SELECTED", campaignType);
};
export const merchantReportsCampaignStatusEvent = (campaignStatus) => {
  return publishEvent("MPD_REPORTS_CAMPAIGN_STATUS_SELECTED", campaignStatus);
};
export const merchantReportsCampaignDateRangeChangedEvent = (
  campaignDateRange
) => {
  return publishEvent(
    "MPD_REPORTS_CAMPAIGN_DATE_RANGE_CHANGED",
    campaignDateRange
  );
};
export const merchantReportsBestPerformingCampaignSelectedEvent = (
  sortedBy
) => {
  return publishEvent(
    "MPD_REPORTS_BEST_PERFORMING_CAMPAIGN_SELECTED",
    sortedBy
  );
};
export const merchantReportsCampaignReportsClickedEvent = (campaignData) => {
  return publishEvent("MPD_REPORTS_CAMPAIGN_REPORTS_CLICKED", campaignData);
};

export const merchantReportsCampaignViewMoreClickedEvent = (campaignCount) => {
  return publishEvent("MPD_REPORTS_CAMPAIGN_VIEW_MORE_CLICKED", campaignCount);
};

// MPD EVENTS ENDS

// RPD events starts
export const rewardPartnerLogoClickedEvent = () => {
  return publishEvent("RPD_LOGO_CLICKED", {});
};
export const rewardCampaignNavClickedEvent = () => {
  return publishEvent("RPD_CAMPAIGNS_NAV_CLICKED", {});
};

export const rewardPartnerReportsNavClickedEvent = () => {
  return publishEvent("RPD_REPORTS_NAV_CLICKED", {});
};

export const rewardCampaignCreateClickedEvent = (campaignData) => {
  return publishEvent("RPD_CAMPAIGN_CREATE_CLICKED", campaignData);
};
export const rewardCampaignUpdateClickedEvent = (campaignData) => {
  return publishEvent("RPD_CAMPAIGN_UPDATE_CLICKED", campaignData);
};
export const rewardCampaignDetailClickedEvent = (campaignData) => {
  return publishEvent("RPD_CAMPAIGN_DETAIL_LINK_CLICKED", {
    campaignData: campaignData,
  });
};

export const listPageCreateRewardCampaignButtonClickedEvent = () => {
  return publishEvent("RPD_LIST_PAGE_CREATE_CAMPAIGN_BUTTON_CLICKED", {});
};
export const rewardCampaignStatusSwitchButtonClickedEvent = (newStatus) => {
  return publishEvent("RPD_CAMPAIGN_STATUS_SWITCH_BUTTON_CLICKED", newStatus);
};

export const rewardCampaignFieldChangedEvent = (fieldName, fieldData) => {
  return publishEvent(`RPD_CAMPAIGN_${fieldName}_CHANGED`, fieldData);
};

export const rewardCampaignRewardCodeTypeFieldChangedEvent = (fieldData) => {
  return publishEvent(`RPD_CAMPAIGN_REWARD_CODE_TYPE_CHANGED`, fieldData);
};

export const rewardPartnerReportsCampaignTypeSelectedEvent = (campaignType) => {
  return publishEvent("RPD_REPORTS_CAMPAIGN_TYPE_SELECTED", campaignType);
};
export const rewardPartnerReportsCampaignStatusEvent = (campaignStatus) => {
  return publishEvent("RPD_REPORTS_CAMPAIGN_STATUS_SELECTED", campaignStatus);
};
export const rewardPartnerReportsCampaignDateRangeChangedEvent = (
  campaignDateRange
) => {
  return publishEvent(
    "RPD_REPORTS_CAMPAIGN_DATE_RANGE_CHANGED",
    campaignDateRange
  );
};
