import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import "../../assets/scss/style.scss";
import Aux from "../../../merchant_partner_dashboard/hoc/_Aux";
import Breadcrumb from "../../../merchant_partner_dashboard/App/layout/AdminLayout/Breadcrumb";
import { DisplayText, TextContainer } from "@shopify/polaris";
import LoggedOutTopNav from "../../LoggedOutTopNav/LoggedOutTopNav";
import PlantGif from "../../assets/images/json_gif/Plant.json";
import OfferxpLogo from "../../assets/images/offerxp-logo.png";
import { getMerchantDataFromLocalStorage } from "../../oxp_utils/common_utils";
import FooterVersionString from "../../Navigation/NavFooter/footerVersion";
import Logger from "../../oxp_utils/logger";

class WelcomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
    };
  }
  componentDidMount() {
    // if (!isPageAllowed()) {
    //   window.location.replace("/");}
    // else {
    //   console.log("")
    //   removeAllowedPages()};
  }

  page() {
    const merchantData = JSON.parse(getMerchantDataFromLocalStorage());
    // let merchantFirstName = merchantData?.username?.split(' ')?.length > 1 ? merchantData.username?.split(' ')[0] : "";
    let merchantFirstName = merchantData?.username ? merchantData.username : "";
    return (
      <Aux>
        <Breadcrumb />
        <LoggedOutTopNav />
        <div className="pre-login-wrapper">
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="card shadow-none">
                <div className="card-body">
                  <div className="text-center">
                    {/* <div className="oxp-branding">
                      <img
                        className=""
                        src={OfferxpLogo}
                        width="173"
                        height="147"
                      />
                    </div> */}
                  </div>
                  <h3 className="mb-4 text-center">Welcome {merchantFirstName},</h3>
                  <p className="sub-text mt-5">
                    You have successfully signed up for OfferXP. You need to
                    create an online store to start your reward integration
                  </p>

                  <div className="text-center mt-5">
                    <Link to="/auth/create-store/">
                      <button
                        className="btn btn-next shadow-2 mb-5 mt-4"
                      // onClick={() =>
                      //   this.setState({
                      //     activePage: 1,
                      //   })
                      // }
                      >
                        Create Store
                      </button>
                    </Link>
                  </div>
                  <div style={{ marginBottom: "12rem" }}></div>
                </div>
              </div>
              <FooterVersionString />
            </div>
          </div>
          <div className="page-gif">
            <div className="gif-wrapper">
              <Lottie animationData={PlantGif} />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
  render() {
    return this.page();
    // if (isPageAllowed(window.location.pathname)) return this.page();
    // else return null;
  }
}

export default WelcomeScreen;
