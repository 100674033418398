import * as axios from "axios";
import config from "../../merchant_partner_dashboard/config";
import { getActiveStoreFromLocalStorage } from "../oxp_utils/common_utils";
import Logger from "../oxp_utils/logger";
import UiUtils from "../oxp_utils/ui-utils";
import CookieService from "./cookie-service";
import store from "../store/reduxStore";

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (CookieService.getToken() !== null) {
      console.log(
        "getActiveStoreFromLocalStorage() API",
        getActiveStoreFromLocalStorage()
      );
      config.headers["Authorization"] = `Token ${CookieService.getToken()}`;
      config.headers["Active-Store"] = getActiveStoreFromLocalStorage();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        console.log("ERER");
        CookieService.deleteToken();
        window.location.href = config.authPage;
      } else if (error.response.status === 500) {
        UiUtils.showTechErrorPopup();
      }
    } else {
      //Handle no response case
      UiUtils.showErrorPopup("OfferXP", "Problem connecting to servers!");
    }
    return Promise.reject(error);
  }
);

export default class OfferxpApiClient {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL;
    this.shopify_url = this.api_url + "shopify/";
    this.merchant_url = this.api_url + "merchant/";
    this.reward_partner_url = this.api_url + "reward-partner/";
  }

  init = () => {
    this.api_token = CookieService.getToken();

    let headers = {
      Accept: "application/json",
    };
    if (!this.client) {
      this.client = axios.create({
        baseURL: this.api_url,
        timeout: 31000,
        headers: headers,
        validateStatus: function (status) {
          return status === 200;
        },
      });
    }

    return this.client;
  };

  fetchStores(owner_type = "mpd") {
    // return axiosApiInstance.get(`${this.shopify_url}stores/`, { params: { owner_type: store.getState()?.data_states?.selectedApp || localStorage.getItem('role') } });
    return axiosApiInstance.get(`${this.shopify_url}stores/`, {
      params: { owner_type: owner_type || localStorage.getItem("role") },
    });
  }

  fetchStore(store_url) {
    return axiosApiInstance.get(`${this.shopify_url}stores/${store_url}/`, {});
  }

  deleteStore(store_url) {
    return axiosApiInstance.delete(
      `${this.shopify_url}stores/${store_url}/`,
      {}
    );
  }

  fetchStoreData() {
    return axiosApiInstance
      .get(this.shopify_url + "store_settings/", {})
      .then((response) => response)
      .catch((err) => console.log(err));
  }

  fetchDashboardActivity(page, fetchAllData) {
    // return axiosApiInstance.get(this.shopify_url + "activity/", {});
    return axiosApiInstance.get(
      `${this.shopify_url}activity/${page}/?show_all=${fetchAllData}`,
      {}
    );
  }

  userLogin(email, password, otp, req_no) {
    // return this.init().post(this.api_url + 'rest-auth/login/', {'username': username, 'password': password}, {})
    return this.init().post(
      this.api_url + "api/token/",
      // { username: username, password: password },
      { email: email, password: password, otp: otp, req_no: req_no },
      {}
    );
  }

  userLogout() {
    return axiosApiInstance.post(this.api_url + "api/logout/");
  }

  initiateStoreConnection(storeURL) {
    return axiosApiInstance.post(this.shopify_url + "connect_store/", {
      store_url: storeURL,
    });
  }

  saveStoreConnection(authData) {
    return axiosApiInstance.post(this.shopify_url + "auth/callback/", {
      ...authData,
    });
  }

  disconnectStore() {
    return axiosApiInstance.post(this.shopify_url + "disconnect_store/");
  }

  updateOffersEnabled(state) {
    return axiosApiInstance.post(this.shopify_url + "store_settings/", {
      action: "offers_enabled",
      state: state,
    });
  }

  fetchRewardCampaigns() {
    return axiosApiInstance.get(this.reward_partner_url + "r_campaigns/");
  }

  fetchRewardCampaign(campaign_id) {
    return axiosApiInstance.get(
      this.reward_partner_url + "r_campaigns/" + campaign_id + "/"
    );
  }

  addRewardCampaign(data) {
    return axiosApiInstance.post(
      this.reward_partner_url + "r_campaigns/",
      data
    );
  }

  editRewardCampaign(campaign_slug, data) {
    return axiosApiInstance.put(
      `${this.reward_partner_url}r_campaigns/${campaign_slug}/`,
      data
    );
  }

  deleteRewardCampaign(campaign_id) {
    return axiosApiInstance.delete(
      `${this.reward_partner_url}r_campaigns/${campaign_id}/`
    );
  }

  fetchMerchantCampaigns(analyticsPage = false, urlParams) {
    return axiosApiInstance.get(this.merchant_url + "campaigns/", {
      params: urlParams,
    });
  }

  fetchMerchantCampaign(campaign_slug) {
    return axiosApiInstance.get(
      this.merchant_url + "campaigns/" + campaign_slug + "/"
    );
  }

  fetchStoreAnalyticsReports(urlParams) {
    return axiosApiInstance.get(this.merchant_url + "analytics/", {
      params: urlParams,
    });
  }
  fetchRewardDashboardAnalyticsReports(urlParams) {
    return axiosApiInstance.get(this.reward_partner_url + "analytics/", {
      params: urlParams,
    });
  }
  fetchMerchantCampaignAnalytics(campaign_slug) {
    return axiosApiInstance.get(
      this.merchant_url + "campaigns/" + campaign_slug + "/analytics/"
    );
  }

  addMerchantCampaign(data) {
    return axiosApiInstance.post(this.merchant_url + "campaigns/", data);
  }

  editMerchantCampaign(campaign_slug, data, isFile) {
    return axiosApiInstance.put(
      `${this.merchant_url}campaigns/${campaign_slug}/`,
      data,
      {
        headers: {
          "Content-Type": isFile ? "multipart/form-data" : "application/json",
        },
      }
    );
  }
  toggleMerchantCampaignStatus(campaign_slug, data) {
    return axiosApiInstance.put(
      `${this.merchant_url}campaigns/${campaign_slug}/disable/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  fetchShopifyStoreProducts(page, title = null) {
    return axiosApiInstance.get(`${this.shopify_url}products/?title=${title}`);
  }

  deleteMerchantCampaign(campaign_slug) {
    return axiosApiInstance.delete(
      `${this.merchant_url}campaigns/${campaign_slug}/`
    );
  }

  fetchCouponCode(reference_number) {
    return axiosApiInstance.get(
      this.merchant_url + `coupons/${reference_number}/`
    );
  }

  fetchCouponCodes() {
    return axiosApiInstance.get(this.merchant_url + "coupons/");
  }

  updateCouponCodes(coupon_code_id, data) {
    return axiosApiInstance.put(
      this.merchant_url + `coupons/${coupon_code_id}/`,
      data
    );
  }

  deleteCouponCodes(reference_numbers) {
    return axiosApiInstance.delete(this.merchant_url + "coupons/", {
      data: reference_numbers,
    });
  }

  downloadCouponCodes(campaign_slug) {
    return axiosApiInstance.get(
      `${this.merchant_url}coupons/${campaign_slug}/download/`
    );
  }

  fetchRewards() {
    return axiosApiInstance.get(this.reward_partner_url + "campaigns/");
  }

  fetchProducts() {
    return axiosApiInstance.get(this.merchant_url + "products/");
  }

  changePassword(data) {
    return axiosApiInstance.post(
      this.merchant_url + "settings/?action=password",
      data
    );
  }

  obtainOtp(action, data) {
    return axiosApiInstance.post(`${this.shopify_url}obtain_dashboard_otp/`, {
      action: action,
      ...data,
    });
  }

  resetPassword(data) {
    return axiosApiInstance.post(
      this.api_url + "rest-auth/password/reset/",
      data
    );
  }
  resetPasswordParamsConfirm(data) {
    return axios.post(this.api_url + "api/password/reset/confirm/", data);
  }
  verifyEmailCheck(data) {
    return axios.get(this.api_url + "api/email/verify/", { params: data });
  }

  verifyEmailConfirm(data) {
    return axios.post(this.api_url + "api/email/verify/", data);
  }
  //User onboarding APIs

  registerUser(payload) {
    /**
     * Payload - {first_name:xxx, last_name:xxxx, mobile_number:xxxx, country_code:xx, email:xxxxx@xxxx.xx}
     * */
    return this.init().post(this.shopify_url + "dashboard_signup/", payload);
  }

  userSignup(payload) {
    /**
     * Payload - {user_name:xxx, password:xxxx}
     * */
    return this.init().put(this.shopify_url + "dashboard_signup/", payload);
  }

  fetchRewardCriteria(ref_id) {
    // Using one function to get both the reward criteria list and reward criteria detail
    if (ref_id) {
      return axiosApiInstance.get(
        `${this.merchant_url}reward_settings/${ref_id}/`
      );
    }
    return axiosApiInstance.get(`${this.merchant_url}reward_settings/`);
  }

  updateRewardSettings(payload) {
    /**
     * Payload - {use_criteria: boolean,
    moa: number,
    moq: number,
    reward_notification_mode: [],}
     * */
    if (payload && payload.moa === "") payload.moa = 0;
    if (payload && payload.moq === "") payload.moq = 0;

    return axiosApiInstance.post(this.merchant_url + "reward_settings/", {
      ...payload,
      store: getActiveStoreFromLocalStorage(),
    });
  }

  addRewardCriteria(payload) {
    /**
     * Payload - {
    name: string,
    store: string,
    use_criteria: boolean,
    criteria_applies_to: string,
    selected_products: [],
    moa: number,
    moq: number,
    ref_id: uuid,
    reward_notification_mode: [],
  }
     * */
    if (payload && payload.moa === "") payload.moa = 0;
    if (payload && payload.moq === "") payload.moq = 0;

    return axiosApiInstance.post(this.merchant_url + "reward_settings/", {
      ...payload,
      store: getActiveStoreFromLocalStorage(),
    });
  }

  editRewardCriteria(ref_id, payload, isFile) {
    /**
     * Payload - {
    name: string,
    store: string,
    use_criteria: boolean,
    criteria_applies_to: string,
    selected_products: [],
    moa: number,
    moq: number,
    ref_id: uuid,
    reward_notification_mode: [],
  }
     * */
    if (payload && payload.moa === "") payload.moa = 0;
    if (payload && payload.moq === "") payload.moq = 0;
    return axiosApiInstance.put(
      `${this.merchant_url}reward_settings/${ref_id}/`,
      payload,
      {
        headers: {
          "Content-Type": isFile ? "multipart/form-data" : "application/json",
        },
      }
    );
  }

  deleteRewardCriteria(ref_id) {
    return axiosApiInstance.delete(
      `${this.merchant_url}reward_settings/${ref_id}/`
    );
  }

  createStore = (payload) => {
    /**
     * Payload - {store_name:xxx, store_type:xxxx, store_url}
     * */
    return axiosApiInstance.post(this.shopify_url + "stores/", payload);
  };

  updateStore = (payload, storeUrl) => {
    /**
     * Payload - {store_name:xxx, store_type:xxxx, store_url}
     * */
    return axiosApiInstance.put(
      `${this.shopify_url}stores/${storeUrl}/`,
      payload
    );
  };

  fetchProfile = () => {
    return axiosApiInstance.get(`${this.merchant_url}profile/`);
  };
  updateProfile = (payload) => {
    /**
     * Payload - {active_role:xxx, active_store: xxxx, ...}
     * */
    return axiosApiInstance.put(`${this.merchant_url}profile/`, payload);
  };

  fetchMerchantFaq = (topic) => {
    let url;
    if (topic === undefined) {
      url = `${this.merchant_url}faqs/`;
    } else {
      url = `${this.merchant_url}faqs/?topic=${topic}`;
    }
    return axiosApiInstance.get(url);
  };

  fetchRewardFaq = (topic) => {
    let url;
    if (topic === undefined) {
      url = `${this.reward_partner_url}faqs/`;
    } else {
      url = `${this.reward_partner_url}faqs/?topic=${topic}`;
    }
    return axiosApiInstance.get(url);
  };
}
