import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import * as serviceWorker from "./serviceWorker";

import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import config from "./merchant_partner_dashboard/config";
import MainApp from "./app";
import store from "./common/store/reduxStore";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <AppProvider i18n={enTranslations}>
        <MainApp />
      </AppProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
