import React, { Suspense, useEffect } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import CookieService from "../../common/services/cookie-service";
import "font-awesome/scss/font-awesome.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const AdminLayout = Loadable({
    loader: () => import("./layout/AdminLayout"),
    loading: Loader,
});
const DashboardActivity = React.lazy(() =>
    import("../Demo/Dashboard/dashboard-activity")
); const ReportPage = React.lazy(() =>
    import("../Demo/Dashboard/reports")
);

export default function App(props) {
    const history = useHistory();
    useEffect(() => {
        if (!CookieService.isLoggedIn()) {
            history.push("/auth/signin");
        }
    }, []);

    const menu = routes.map((route, index) => {
        return route.component ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => <route.component {...props} />}
            />
        ) : null;
    });
    return (
        <Aux>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Redirect exact from="/" to="campaigns" />
                        {menu}
                        <Route path="/" component={AdminLayout} />
                        <Route path="/activity" component={DashboardActivity} />
                        <Route path="/reports" component={ReportPage} />
                    </Switch>
                </Suspense>
            </ScrollToTop>
        </Aux>
    );
}
