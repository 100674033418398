import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {Switch, Route, useHistory, Redirect} from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import CookieService from "../../common/services/cookie-service";
import "font-awesome/scss/font-awesome.scss";
import OfferxpApiClient from "../../common/services/api-services";
import config from "../config";
import Logger from "../../common/oxp_utils/logger";
import {
  saveActiveStoreToLocalStorage,
  saveMerchantDataToLocalStorage,
  saveStoresToLocalStorage,
  getMerchantDataFromLocalStorage,
} from "../../common/oxp_utils/common_utils";

import Tour from "reactour";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

export default function App(props) {
  const history = useHistory();
  const apiClient = new OfferxpApiClient();
  const [dashboardVisibility, setDashboardVisibility] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const isLoadingRef = useRef(false);
  useEffect(() => {
    if (
      CookieService.isLoggedIn() &&
      !(window.location.pathname === "/auth/callback/")
    ) {
      /**
       * Removing the new store entry from local storage.
       * This flag is used only for detecting the presence of a shopify store in OfferXP
       * If an existing store is detected then the UI should show a propmpt,
       * if user try to create a new OfferXP account. Instead of login with existing credentials.
       * After login this flag is useless so removing it from storage.
       */
      localStorage.removeItem(config.isExistingStore);
      /**
       * Check if there is a shopify url is present
       * if present, it means the app just finished the shopify installation
       * Then redirect to store cretion page for updating or creating the shopify store
       */
      if (
        localStorage.getItem("shopify_url") &&
        !(window.location.pathname === config.createStorePage)
      ) {
        history.replace(config.createStorePage);
      }

      Logger.log("debug27", "entering logged in part..");
      let stores = localStorage.getItem("stores")
        ? JSON.parse(localStorage.getItem("stores"))
        : null;
      if (stores && stores.length > 0) {
        if (isSignupApproved()) {
          setDashboardVisibility(true);
        } else {
          history.replace(config.reviewPage);
        }
      } else {
        stores = null;
        Logger.log("debug27", "calling fetchstore api..");
        isLoadingRef.current = true;
        apiClient
          .fetchStores(props?.selectedRole)
          .then((response) => {
            isLoadingRef.current = false;
            stores = response.data.stores;
            Logger.log("debug27", response.data.stores);
            Logger.log("debug27", stores);
            saveStoresToLocalStorage(response.data.stores);
            let merchantData = {
              username: response.data.username,
              email: response.data.email,
              is_approved: response.data.is_approved,
            };
            saveMerchantDataToLocalStorage(merchantData);
            //Logger.log("active-store",stores[0].store_url);

            if (stores.length > 0) {
              // TODO: find a better way to set active-store
              Logger.log("debug27", "saving active store..");
              saveActiveStoreToLocalStorage(stores[0].store_url);
              //Logger.log("active-store",stores[0].store_url);
            }
          })
          .then(() => {
            Logger.log("debug27", stores?.length);
            if (stores && stores.length > 0) {
              if (isSignupApproved()) {
                setDashboardVisibility(true);
              } else {
                history.replace(config.reviewPage);
              }
            } else {
              //launch welcome
              Logger.log("debug27", "launching welcome screen...");
              history.replace(config.launchPage);
            }
          });
      }
    } else {
      Logger.log("extu10", "entering into not login...");
      switch (window.location.pathname) {
        case "/auth/signup/":
          break;
        case "/auth/signup1/":
          break;
        case "/auth/callback/":
          break;
        case "/auth/reset-password/":
          break;
        case "/auth/reset-password-confirm/":
          break;
        case "/auth/email/verify/":
          break;
        default:
          Logger.log("debug27", "redirecting to auth page....");
          history.replace(config.authPage);
          break;
      }
    }
    setIsTourOpen(true);
  }, []);

  const isSignupApproved = () => {
    let merchantData = getMerchantDataFromLocalStorage()
      ? JSON.parse(getMerchantDataFromLocalStorage())
      : null;
    console.log("ME", merchantData);
    return merchantData?.is_approved;
  };

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  const steps1 = [
    {
      selector: ".campaign-nav",
      content: "Create your campaigns here ! Manage and edit created campaigns",
    },
    {
      selector: ".reports-nav",
      content: "Check reports and starts for your campaigns.",
    },
  ];

  return (
    <Aux>
      {/* <Tour
        steps={steps1}
        isOpen={isTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor="#5cb7b7"
        disableFocusLock={true}
      /> */}
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            {dashboardVisibility ? (
              <Redirect exact from="/" to="campaigns" />
            ) : null}
            {menu}
            <Route
              path="/"
              component={dashboardVisibility ? AdminLayout : Loader}
            />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
}
