import Logger from "./logger";

export const isPositiveInt = (val, lBound = 0, uBound = 10000000) => {
  if (val) {
    return /^[0-9]+$/.test(val) && val > lBound && val <= uBound;
  }
  return false;
};
export const isPageAllowed = (pathname) => {
  // temporary logic to block  remove this
  if (localStorage.getItem("shpg_path") === pathname) return true;
  return false;
};
export const setAllowedPagePath = (pathname) => {
  // temporary logic to block  remove this
  localStorage.setItem("shpg_path", pathname);
};
export const removeAllowedPages = () => {
  // temporary logic to block  remove this
  localStorage.removeItem("shpg_path");
};
export const isString = (myVar) => {
  return typeof myVar === "string" || myVar instanceof String;
};
export const getString = (myVar) => {
  // returns the string if type is string else null
  return isString(myVar) ? myVar : null;
};
export const isValidString = (myVar) => {
  // check for undefined as well
  return isString(myVar) && myVar !== "undefined";
};
export const getValidString = (myVar) => {
  // returns the string if type is string else null
  return isValidString(myVar) ? myVar : null;
};

export const getErrorResponseMessage = (error) => {
  // Extract any possible error message from the error Response of an api call
  let msg;
  try {
    msg = getString(error?.response?.data) ||
      Array.isArray(error?.response?.data) ? getString(error?.response?.data[0]) : null ||
        getString(error?.response?.data?.detail) ||
        isString(error?.response?.data?.message) ? error?.response?.data?.message : null ||
          Array.isArray(error?.response?.data?.message?.non_field_errors)
      ? error?.response?.data?.message?.non_field_errors[0] : null ||
        Array.isArray(error?.response?.data?.non_field_errors)
        ? error?.response?.data?.non_field_errors[0] : null ||
          Array.isArray(error?.response?.data?.error)
          ? error?.response?.data?.error[0]
          : null
            ||
            Array.isArray(error?.response?.data)
            ? error?.response?.data[0]
            : null
            || "Some unexpected error occured!";
  }
  catch (err) {
    console.log("ERROR while parsing error object", err)
    msg = "Some unexpected error occured!"
  }
  return msg;
};

export const getNonFieldErrors = (error) => {
  //Logger.log("non_filed", error?.response?.data?.error?.non_field_errors);
  return error?.response?.data?.error?.non_field_errors;
};
