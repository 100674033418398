import React from "react";
import Lottie from "lottie-react";

import "../../assets/scss/style.scss";
import Aux from "../../../merchant_partner_dashboard/hoc/_Aux";
import Breadcrumb from "../../../merchant_partner_dashboard/App/layout/AdminLayout/Breadcrumb";
import LoggedOutTopNav from "../../LoggedOutTopNav/LoggedOutTopNav";
import { DisplayText, TextContainer } from "@shopify/polaris";
import OfferxpApiClient from "../../services/api-services";
import config from "../../../merchant_partner_dashboard/config";
import Logger from "../../oxp_utils/logger";
import {
  getMerchantDataFromLocalStorage,
  saveMerchantDataToLocalStorage,
  saveStoresToLocalStorage
} from "../../oxp_utils/common_utils";
import PlantGif from "../../assets/images/json_gif/Plant.json";
import OfferxpLogo from "../../assets/images/offerxp-logo.png";
import FooterVersionString from "../../Navigation/NavFooter/footerVersion";

class StoreReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
    };
    this.apiClient = new OfferxpApiClient();
  }

  isSignupApproved = () => {
    let merchantData = getMerchantDataFromLocalStorage()
      ? JSON.parse(getMerchantDataFromLocalStorage())
      : null;
    return merchantData?.is_approved;
  };

  componentDidMount() {
    this.apiClient
      .fetchStores()
      .then((response) => {
        saveStoresToLocalStorage(response.data.stores)
        let merchantData = {
          username: response.data.username,
          email: response.data.email,
          is_approved: response.data.is_approved,
        };
        saveMerchantDataToLocalStorage(merchantData)
      })
      .then((result) => {
        // If signup is approved then, user should be redirected to the campaign page.
        if (this.isSignupApproved()) {
          Logger.log("debug27", "signup approved. Redirecting to campaign..");
          window.location = config.defaultPath;
        }
      });
  }

  render() {
    const merchantData = JSON.parse(getMerchantDataFromLocalStorage());
    let merchantFirstName = merchantData?.username?.split(' ')?.length > 1 ? merchantData.username?.split(' ')[0] : ""
    return (
      <Aux>
        <Breadcrumb />
        <LoggedOutTopNav />
        <div className="pre-login-wrapper">
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="card shadow-none">
                <div className="card-body">
                  <div className="text-center">
                    <div className="oxp-branding">
                      <img
                        className=""
                        src={OfferxpLogo}
                        width="173"
                        height="147"
                      />
                    </div>
                  </div>
                  <h5 className="mb-4 text-center">You're All Caught Up &#10003;</h5>
                  <div className="p-5">
                    <p className="sub-text" style={{ lineHeight: "1.8" }}>
                      Thanks for yor interest in partnering with OfferXP! Your
                      application is successfully submitted for the review. We
                      will notify you when the review process is complete.
                      Thanks!
                    </p>
                  </div>
                </div>
              </div>
              <FooterVersionString />
            </div>
          </div>
          <div className="page-gif">
            <div className="gif-wrapper">
              <Lottie animationData={PlantGif} />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default StoreReview;
