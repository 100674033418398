import * as actionTypes from "../actions";

const initialState = {
  selectedCampaign: null, // last selected campaign object
  rewards: null,
  products: null,
  campaignTitle: null,
  selectedReward: null,
  selectedProduct: null,
  campaignRule: null,
  selectedRole: null,
  selectedApp: null,
  userProfileData: null,
  // data states for top nav filters
  campaign_type_filter: [],
  campaign_status_filter: null,
  report_filter_date_to: new Date(),
  report_filter_date_from: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 7
  ),
};

const dataStatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload,
      };

    case actionTypes.UPDATE_REWARDS:
      return {
        ...state,
        rewards: action.payload,
      };

    case actionTypes.UPDATE_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case actionTypes.UPDATE_CAMPAIGN_TITLE:
      return {
        ...state,
        campaignTitle: action.payload,
      };
    case actionTypes.UPDATE_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case actionTypes.UPDATE_SELECTED_REWARD:
      return {
        ...state,
        selectedReward: action.payload,
      };
    case actionTypes.UPDATE_CAMPAIGN_RULE:
      return {
        ...state,
        campaignRule: action.payload,
      };
    case actionTypes.SET_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case actionTypes.SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };
    case actionTypes.SET_DASHBOARD_APP:
      return {
        ...state,
        selectedApp: action.payload,
      };
    case actionTypes.SET_CAMPAIGN_TYPE_FILTER:
      return {
        ...state,
        campaign_type_filter: action.payload,
      };
    case actionTypes.SET_CAMPAIGN_STATUS_FILTER:
      return {
        ...state,
        campaign_status_filter: action.payload,
      };
    case actionTypes.SET_REPORT_FILTER_DATE_TO:
      return {
        ...state,
        report_filter_date_to: action.payload,
      };
    case actionTypes.SET_REPORT_FILTER_DATE_FROM:
      return {
        ...state,
        report_filter_date_from: action.payload,
      };

    default:
      return state;
  }
};

export default dataStatesReducer;
