// import swal from '@sweetalert/with-react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SweetAlert = withReactContent(Swal);

// UI - utilities for OfferXP
// Ref...
//https://sweetalert.js.org/guides/

const UiUtils = {
	showSimpleAlert: (msg) => {
		SweetAlert.fire(msg);
	},
	showSimplePopup: (title, msg) => {
		return SweetAlert.fire(title, msg);
	},
	showSuccessPopup: (title, msg) => {
		return SweetAlert.fire(title, msg, "success");
	},
	showErrorPopup: (title, msg) => {
		return SweetAlert.fire(title, msg, "error");
	},
	showInfoPopup: (title, msg) => {
		return SweetAlert.fire(title, msg, "info");
	},
	showWarningPopup: (title, msg) => {
		return SweetAlert.fire(title, msg, "warning");
	},
	showCustomAlert: (data) => {
		return SweetAlert.fire(data);
	},
	showToast: (type, title) => {
		return Swal.mixin({
			toast: true,
			position: "bottom-right",
			iconColor: type,
			customClass: {
				popup: "colored-toast",
			},
			showConfirmButton: false,
			timer: 2500,
			timerProgressBar: true,
		}).fire({icon: type, title: title});
	},
	showConfirmDialog: (title, text, confirmButtonText) => {
		return SweetAlert.fire({
			title: title,
			text: text,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: confirmButtonText,
		});
	},
	showTechErrorPopup: () => {
		return SweetAlert.fire(
			"Oops! Something went wrong!",
			"The application has encountered an error. Our technical team have been automatically notified and will be looking into this with the utmost urgency.",
			"error"
		);
	},
};

export default UiUtils;
