import React from "react";
import Lottie from "lottie-react";

import "../../assets/scss/style.scss";
import Aux from "../../../merchant_partner_dashboard/hoc/_Aux";
import Breadcrumb from "../../../merchant_partner_dashboard/App/layout/AdminLayout/Breadcrumb";
import OfferxpApiClient from "../../services/api-services";
import { Code } from "react-content-loader";
import UiUtils from "../../oxp_utils/ui-utils";
import Logger from "../../oxp_utils/logger";
import LoggedOutTopNav from "../../LoggedOutTopNav/LoggedOutTopNav";
import config from "../../../merchant_partner_dashboard/config";
import {
  saveMerchantDataToLocalStorage,
  saveStoresToLocalStorage,
} from "../../oxp_utils/common_utils";
import OfferxpLogo from "../../assets/images/offerxp-logo.png";
import StoreGIF from "../../assets/images/json_gif/StoreReview.json";
import { getMerchantDataFromLocalStorage } from "../../oxp_utils/common_utils";
import Select from "react-select";
import ShopifyLogo from "../../assets/images/widget/shopify.png";
import WebsiteLogo from "../../assets/images/widget/internet.png";
import AndroidLogo from "../../assets/images/widget/android.png";
import IosLogo from "../../assets/images/widget/apple.png";
import FooterVersionString from "../../Navigation/NavFooter/footerVersion";
import { getErrorResponseMessage } from "../../oxp_utils";

class CreateStore extends React.Component {
  constructor(props) {
    super(props);
    this.apiClient = new OfferxpApiClient();

    const errorStates = {
      storeNameFieldError: false,
      storeTypeFieldError: false,
      storeUrlFieldError: false,
    };

    this.state = {
      username: "",
      activePage: 0, // Set to -1 for loader
      ...errorStates,
      autoCreateStore: false,
      storeData: {
        store_name: "",
        store_type: "select store",
        store_url: "",
      },
    };
  }

  componentDidMount() {
    let shopifyStoreName =
      JSON.parse(localStorage.getItem(config.shopifyData))?.name ??
      "shopify-default";
    let shopifyStoreUrl = localStorage.getItem("shopify_url");
    Logger.log("debug27", "store creation page...");
    if (shopifyStoreUrl) {
      Logger.log("debug27", "prearing for store autocreation...");
      this.autoCreateShopifyStore(shopifyStoreUrl, shopifyStoreName);
    }
    if (getMerchantDataFromLocalStorage()) {
      let merchantData = JSON.parse(getMerchantDataFromLocalStorage());
      const username = merchantData.username.split(" ")[0];
      this.setState({
        username: username,
      });
    }
  }

  autoCreateShopifyStore = (storeUrl, storeName) => {
    let storeData = {
      store_name: storeName,
      store_type: "shopify",
      store_url: storeUrl,
    };
    this.createStore(storeData);
    this.setState({
      autoCreateStore: true,
      storeData: storeData,
    });
  };

  showLoader() {
    this.setState({
      activePage: -1,
    });
  }

  createStore = (storeData) => {
    if (
      this.state.storeData?.store_name?.length === 0 ||
      this.state.storeData?.store_type?.length === 0 ||
      this.state.storeData?.store_url?.length === 0
    ) {
      UiUtils.showToast("error", `Please fill all the fields`);
      return;
    }
    this.showLoader();
    Logger.log("debug27", "calling create store api...");
    this.apiClient
      .createStore(storeData)
      .then(
        (response) => {
          Logger.log(
            "debug27",
            "store creation api response...",
            response.data
          );
          this.apiClient
            .fetchStores()
            .then((response) => {
              Logger.log(
                "debug28",
                "store fetch api response...",
                response.data
              );
              saveStoresToLocalStorage(response.data.stores);

              let merchantData = {
                username: response.data.username,
                email: response.data.email,
                is_approved: response.data.is_approved,
              };
              saveMerchantDataToLocalStorage(merchantData);
              let stores = response.data.stores;
              Logger.log("CS102", stores, response.data);
              if (response.data?.active_store) {
                Logger.log(
                  "debug28",
                  "active store 1",
                  response.data.active_store
                );
                localStorage.setItem(
                  "active-store",
                  response.data.active_store
                );
              } else {
                if (stores.length > 0) {
                  Logger.log("debug28", "active store 2", stores[0].store_url);
                  localStorage.setItem("active-store", stores[0].store_url);
                }
              }

              if (this.state.autoCreateStore) {
                localStorage.setItem(
                  "active-store",
                  this.state.storeData.store_url
                );
                localStorage.removeItem("shopify_url");
                //window.location = "/";
              } else {
                Logger.log("debug27", "redirecting to store-review page...");
                window.location = "/auth/store-review/";
              }
            })
            .then((result) => {
              Logger.log("debug27", "redirecting to base path...");
              window.location = "/";
            });
        },
        (error) => {
          this.handleErrors(error.response?.data);
          UiUtils.showToast("error", getErrorResponseMessage(error));
          this.setState({
            activePage: 0,
          });
        }
      )
      .catch((error) => {
        UiUtils.showToast(
          "error",
          "Unexpected error. Check the data connectivity"
        );
        Logger.log(error);
      });
  };

  pageOne = () => {
    //Options array to be used as prop in Select component.
    const storeTypeOptions = [
      {
        value: "shopify",
        text: "Shopify",
        icon: <img style={{ height: "3rem" }} src={ShopifyLogo} alt="icon" />,
      },
      {
        value: "ios",
        text: "iOS",
        icon: <img style={{ height: "3rem" }} src={IosLogo} alt="icon" />,
      },
      {
        value: "android",
        text: "Android",
        icon: <img style={{ height: "3rem" }} src={AndroidLogo} alt="icon" />,
      },
      {
        value: "website",
        text: "Website",
        icon: <img style={{ height: "3rem" }} src={WebsiteLogo} alt="icon" />,
      },
    ];

    // Added the explanation for defining the class in readme file
    const customStylesStoreTypeDropdown = {
      option: (provided, state) => ({
        ...provided,
        color: "#8d9aa9",
        backgroundColor: "#e3eaf3",
      }),
      control: (provided) => ({
        ...provided,
        color: "#8d9aa9",
        marginTop: "20px",
        marginBottom: "20px",
        width: "100%",
        backgroundColor: "#e3eaf3",
        border: "2px solid #cfd9e3",
        borderRadius: "6px",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#8d9aa9",
        paddingLeft: "10px",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "#8d9aa9",
        paddingLeft: "10px",
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: "6px",
        marginTop: 0,
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0,
      }),
    };
    return (
      <Aux>
        <Breadcrumb />
        <LoggedOutTopNav />
        <div className="pre-login-wrapper">
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="card shadow-none">
                <div className="card-body">
                  <div className="text-center">
                    <div className="oxp-branding">
                      <img
                        className=""
                        src={OfferxpLogo}
                        width="173"
                        height="147"
                        alt="OfferXP Logo"
                      />
                    </div>
                    <h3 className="mb-4">Welcome, {this.state.username}</h3>
                  </div>
                  <div className="form-fields mb-5">
                    {this.state.storeNameFieldError ? (
                      <small style={{ color: "red" }}>
                        {this.state.storeNameFieldError}
                      </small>
                    ) : null}
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className={
                          this.state.storeNameFieldError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Store name"
                        value={this.state.storeData.store_name}
                        onChange={(event) => {
                          let storeData = this.state.storeData;
                          storeData.store_name = event.target.value;
                          this.setState({
                            storeData: storeData,
                          });
                        }}
                      />
                    </div>

                    {this.state.storeTypeFieldError ? (
                      <small style={{ color: "red" }}>
                        {this.state.storeTypeFieldError}
                      </small>
                    ) : null}
                    <Select
                      placeholder={
                        <div style={{ align: "left" }}>Select store type</div>
                      }
                      options={storeTypeOptions}
                      styles={customStylesStoreTypeDropdown}
                      onChange={(selectedOption) => {
                        let storeData = this.state.storeData;
                        storeData.store_type = selectedOption.value;
                        if (selectedOption.value === "shopify") {
                          // storeData.store_url = `https://${this.state.storeData.store_name
                          //   .replace(/[^a-zA-Z ]/g, "")
                          //   .replace(/\s+/g, "-")
                          //   .toLowerCase()}.myshopify.com`;
                        } else {
                          storeData.store_url = "";
                        }
                        this.setState({
                          storeData: storeData,
                        });
                      }}
                      getOptionLabel={(label) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {label.icon}
                          <span style={{ marginLeft: 5 }}>{label.text}</span>
                        </div>
                      )}
                    ></Select>

                    {this.state.storeUrlFieldError ? (
                      <small style={{ color: "red" }}>
                        {this.state.storeUrlFieldError}
                      </small>
                    ) : null}
                    <div className="input-group mb-4">
                      <input
                        type="text"
                        className={
                          this.state.storeUrlFieldError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder={this.getStoreUrlPlaceHolder(
                          this.state.storeData.store_type
                        )}
                        value={this.state.storeData.store_url}
                        onChange={(event) => {
                          let storeData = this.state.storeData;
                          storeData.store_url = event.target.value;
                          this.setState({
                            storeData: storeData,
                            storeUrlFieldError: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-next shadow-2 mb-4"
                      onClick={() => this.createStore(this.state.storeData)}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
              <FooterVersionString />
            </div>
          </div>
          <div className="page-gif">
            {" "}
            <div className="gif-wrapper">
              <Lottie animationData={StoreGIF} />
            </div>
          </div>
        </div>
      </Aux>
    );
  };

  getStoreUrlPlaceHolder(storeType) {
    switch (storeType) {
      case "shopify":
        return `https://${this.state.storeData.store_name}.myshopify.com`;
      default:
        return "Url of your store (https://business-name.com)";
    }
  }

  renderLoader() {
    return (
      <Aux>
        <Breadcrumb />
        <div className="pre-login-wrapper">
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="card shadow-none">
                <div className="card-body text-center">
                  <div className="mb-4">
                    <div className="oxp-branding">
                      <img
                        className=""
                        src={OfferxpLogo}
                        width="173"
                        height="147"
                        alt="OfferXP Logo"
                      />
                    </div>
                    <p>Please wait...</p>
                  </div>
                  <Code backgroundColor="#04a9f5" />
                </div>
              </div>
            </div>
          </div>
          <div className="page-gif">
            {" "}
            <div className="gif-wrapper">
              <Lottie animationData={StoreGIF} />
            </div>
          </div>
        </div>
      </Aux>
    );
  }

  handleErrors = (errorData) => {
    const errorStates = {
      storeNameFieldError: false,
      storeTypeFieldError: false,
      storeUrlFieldError: false,
    };

    if (errorData) {
      if (Array.isArray(errorData)) {
        if (Array.length > 0) {
          errorStates.storeUrlFieldError = errorData[0];
        }
      } else {
        Object.keys(errorData).forEach((key, index) => {
          switch (key) {
            case "store_name":
              if (errorData[key][0]) {
                errorStates.storeNameFieldError = `*${errorData[key][0]}`;
              }
              break;
            case "store_type":
              if (errorData[key][0]) {
                errorStates.storeTypeFieldError = `*${errorData[key][0]}`;
              }
              break;
            case "store_url":
              if (errorData[key][0]) {
                errorStates.storeUrlFieldError = `*${errorData[key][0]}`;
              }
              break;
            default:
              break;
          }
        });
      }
    }

    this.setState({
      ...errorStates,
    });
  };

  render() {
    switch (this.state.activePage) {
      case 0:
        return this.pageOne();
      // Add more pages
      default:
        return this.renderLoader();
    }
  }
}

export default CreateStore;
