import Cookies from "universal-cookie";

// Provides Cookie related functionalities
const cookies = new Cookies();

const CookieService = {
	get: (key) => {
		return cookies.get(key);
	},
	set: (key, value, options) => {
		cookies.set(key, value, options);
	},
	remove: (key) => {
		cookies.remove(key);
	},
	setToken: (authT) => {
		cookies.set("auth-token", authT, {'path': '/'});
		localStorage.setItem("auth-token", authT);
	},
	deleteToken: () => {
		cookies.remove("auth-token");
		localStorage.removeItem("auth-token");
	},
	getToken: () => {
		return localStorage.getItem("auth-token");
	},
	isLoggedIn: () => {
        let token = localStorage.getItem("auth-token");
		return token !== null && typeof token !== "undefined" && token !== "";
	},
	clearAuthToken: () => {
        cookies.remove("auth-token");
        localStorage.removeItem("auth-token");
	},
};

export default CookieService;
