import React from "react";
import CreateStore from "../common/Authentication/SignUp/CreateStore";
import StoreReview from "../common/Authentication/SignUp/StoreReview";
import WelcomeScreen from "../common/Authentication/SignUp/WelcomeScreen";

// const SignUp = React.lazy(() => import("../common/Authentication/SignIn/SignIn1"));
const SignIn = React.lazy(() => import("../common/Authentication/SignIn/SignIn1"));
const ResetPassword = React.lazy(() =>
  import("../common/Authentication/ResetPassword/ResetPassword")
);
const ShopifyCallback = React.lazy(() =>
  import("./Demo/Dashboard/shopifyAuthCallback")
);
const ResetPasswordConfirm = React.lazy(() =>
  import("../common/Authentication/ResetPassword/ResetPasswordConfirm")
);

const EmailVerification = React.lazy(() =>
  import("../common/Authentication/EmailVerification/EmailVerification")
);
const SignUp = React.lazy(() => import('../common/Authentication/SignUp/SignUp1'));

const route = [
  { path: "/auth/signup", exact: true, name: "Signup", component: SignUp },
  { path: "/auth/signin", exact: true, name: "Signin", component: SignIn },
  {
    path: "/auth/reset-password",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/auth/reset-password-confirm",
    exact: true,
    name: "Reset Password Confirm",
    component: ResetPasswordConfirm,
  },
  {
    path: "/auth/email/verify",
    exact: true,
    name: "Email Verification",
    component: EmailVerification,
  },
  {
    path: "/auth/launch",
    exact: true,
    name: "Welcome",
    component: WelcomeScreen,
  },
  {
    path: "/auth/callback/", // TODO: rename to shopify/callback/
    exact: true,
    name: "Shopify Auth Callback",
    component: ShopifyCallback,
  },
  {
    path: "/auth/create-store",
    exact: true,
    name: "Create Store",
    component: CreateStore,
  },
  {
    path: "/auth/store-review",
    exact: true,
    name: "Store Review",
    component: StoreReview,
  },
];

export default route;
