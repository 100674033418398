import {
  activitiesNavClickedEvent,
  campaignNavClickedEvent,
  manageStoreNavClickedEvent,
  reportsNavClickedEvent,
} from "../common/oxp_utils/analytics-utils";

const menu = {
  items: [
    {
      id: "navigation",
      title: "",
      type: "group",
      icon: "",
      children: [
        {
          id: "campaign-list",
          title: "Campaigns",
          type: "item",
          url: "/campaigns",
          icon: "icomoon-Campaigns",
          // use coloredIcon key and specify the value in the following format
          //coloredIcon: {
          //   icon: "icomoon-icons8-shopify",
          //   paths: ["path1", "path2", "path3"],
          // },  This implementation will only work for icomoon font icons
          classes: "campaign-nav",
          onItemClick: campaignNavClickedEvent,
        },
        // {
        //   id: "dashboard",
        //   title: "Dashboard (Upcoming)",
        //   type: "item",
        //   url: "/dashboard",
        //   icon: "feather icon-home",
        // },
        {
          id: "reports",
          title: "Reports",
          type: "item",
          url: "/reports",
          icon: "icomoon-Reports",
          classes: "reports-nav",
          onItemClick: reportsNavClickedEvent,
        },
        // {
        //   id: "payments",
        //   title: "Billing & Payments (Upcoming)",
        //   type: "item",
        //   url: "",
        //   icon: "fa fa-money",
        // },

        // shopify menu is will be placed from navigation component's componentDidMount.
        // {
        //       id: "shopify",
        //       title: "Shopify",
        //       type: "item",
        //       url: "/shopify-auth",
        //       icon: "fa fa-money",
        //     }

        {
          id: "store-manager",
          title: "Manage Store",
          type: "item",
          url: "/store-manager",
          icon: "icomoon-Manage-Store",
          onItemClick: manageStoreNavClickedEvent,
        },
        {
          id: "activity-log",
          title: "Activities",
          type: "item",
          url: "/activity",
          icon: "icomoon-Activities",
          onItemClick: activitiesNavClickedEvent,
        },
      ],
    },
    // {
    //   id: "seperator",
    //   title: "",
    //   type: "seperator",
    //   customClass: "m-30 p-30", // Defined spacing for seperator, it will render empty space on browser.
    //   icon: "",
    //   children: [
    //     {
    //       id: "",
    //       title: "&nbsp;",
    //       type: "",
    //       url: "",

    //       icon: "",
    //     },
    //   ],
    // },
    // {
    //   id: "footer-support",
    //   title: "Support",
    //   type: "group",
    //   icon: "",
    //   classes: "p-20",
    //   children: [
    //     {
    //       id: "support-mail",
    //       title: "Mail",
    //       type: "item",
    //       classes: "pb-0 m-0",
    //       style: {
    //         fontSize: "10px",
    //       },
    //       url: "mailto:merchant@offerxp.com",
    //       icon: "fa fa-envelope-open",
    //     },
    //     {
    //       id: "support-whatsapp",
    //       title: "Whatsapp",
    //       type: "item",
    //       classes: "pb-0 m-0",
    //       url: "https://wa.me/919678554844",
    //       style: {
    //         fontSize: "10px",
    //       },
    //       target: true,
    //       icon: "fa fa-whatsapp",
    //     },

    //   ],
    // },
    // {
    //   id: "footer-legal",
    //   title: "Legal",
    //   type: "group",
    //   parentClass: "bottom-0",
    //   icon: "",
    //   children: [
    //     {
    //       id: "legal-terms",
    //       title: "Terms",
    //       type: "item",
    //       classes: "pb-0 m-0",
    //       style: {
    //         fontSize: "10px",
    //       },
    //       url: "https://www.offerxp.com/terms.html",
    //       target: true,
    //       icon: "feather icon-file",
    //     },
    //     {
    //       id: "legal-privacy",
    //       title: "Privacy",
    //       type: "item",
    //       classes: "pb-0 m-0",
    //       style: {
    //         fontSize: "10px",
    //       },
    //       url: "https://www.offerxp.com/privacy.html",
    //       icon: "feather icon-shield",
    //     },
    //     {
    //       id: "copyright",
    //       external: false,
    //       title: `Ⓒ ${new Date().getFullYear()} Consights Tech Pvt. Ltd.`,
    //       type: "item",
    //       classes: "pb-0 m-0",
    //       style: {
    //         fontSize: "10px",
    //         bottom: 0,
    //       },
    //       url: "https://offerxp.com/",
    //       icon: "",
    //     },
    //   ],
    // },
  ],
};
export default menu;
