import React from "react";

export default class FooterVersionString extends React.Component {
  render() {
    const style = {
      // text-align: center;
      // font-weight: 400;
      // font-size: 12px;
      // line-height: 14px;
      // color: #4B5D73;
      textAlign: "center",
      fontWeight: "400",
      fontSize: this.props.fontSize || "12px",
      color: this.props.color || "#4B5D73",
    };
    return (
      <React.Fragment>
        <p style={this.props.style || style}>
          {!this.props.props ? (
            <span className="pcoded-mtext" style={{ fontSize: "10px" }}>
              Ⓒ {new Date().getFullYear()} Consights Tech Pvt. Ltd.
            </span>
          ) : null}

          <span style={{fontSize: "10px"}}>
            &emsp;&emsp;v0.81.1{" "}
            {process.env.REACT_APP_DEBUG === "true" ? "d" : null}
          </span>
        </p>
      </React.Fragment>
    );
  }
}
