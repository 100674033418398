import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logger from "./common/oxp_utils/logger";
import * as actionTypes from "./merchant_partner_dashboard/store/actions";

import MerchantApp from "./merchant_partner_dashboard/App";
import RewardApp from "./reward_partner_dashboard/App";
import { APP_CONSTANTS } from "./common/constants";
import UiUtils from "./common/oxp_utils/ui-utils";
import OfferxpApiClient from "./common/services/api-services";
import { setMixPanelUserProfileEvent } from "./common/oxp_utils/analytics-utils";
import CookieService from "./common/services/cookie-service";

class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appType: MerchantApp,
      documentTitle: "OfferXP",
    };
    this.apiClient = new OfferxpApiClient();
  }

  componentDidMount() {
    let onboardingTourStatus = {};
    let role = this.props.selectedApp;
    let selectedApp;
    let documentTitle;
    if (CookieService.isLoggedIn()) {
      // since app.js is common for all pages, a check is needed.
      // to prevent redirect loop. profile api -> 401 -> interceptor -> login page -> profile api
      this.apiClient
        .fetchProfile()
        .then(
          (response) => {
            this.props.updateUserProfileData(response.data);
            setMixPanelUserProfileEvent(response.data);
            role =
              response.data.active_role ??
              APP_CONSTANTS.APP_TYPE_MERCHANT_PARTNER;

            if (role === APP_CONSTANTS.APP_TYPE_REWARD_PARTNER) {
              selectedApp = RewardApp;
              documentTitle = "OfferXP Reward Partner Dashboard";
            } else {
              selectedApp = MerchantApp;
              documentTitle = "OfferXP Merchant Partner Dashboard";
            }
            this.setState({
              appType: selectedApp,
              documentTitle: documentTitle,
            });
            this.props.updateSelectedRole(
              response.data.active_role ??
                APP_CONSTANTS.APP_TYPE_MERCHANT_PARTNER
            );
            // saveActiveRoleToLocalStorage(response.data.active_role ?? 'mpd') // saving default role as mpd if it is coming as null from backend
            if (response.data.onboarding_tour_completed === false) {
              onboardingTourStatus = {
                isTourOpen: true,
                onboardingTourCompleted: false,
              };
            } else {
              onboardingTourStatus = {
                onboardingTourCompleted: true,
              };
            }
            this.setState({
              ...onboardingTourStatus,
              profileResponse: response.data,
            });
          },
          (error) => {
            if (error.response) {
              UiUtils.showToast(
                "error",
                `Request failed: ${error?.response?.data}`
              );
            }
          }
        )
        .catch((error) => {
          if (error.response) {
            UiUtils.showToast(
              "error",
              `Request failed: ${error?.response?.data}`
            );
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    Logger.log("componentDidUpdate", this.props, prevProps, prevState);
    if (this.props?.selectedApp !== prevProps.selectedApp) {
      let selectedApp;
      let documentTitle;
      if (this.props?.selectedApp === APP_CONSTANTS.APP_TYPE_REWARD_PARTNER) {
        selectedApp = RewardApp;
        documentTitle = "OfferXP Reward Partner Dashboard";
      } else {
        selectedApp = MerchantApp;
        documentTitle = "OfferXP Merchant Partner Dashboard";
      }
      this.setState({
        appType: selectedApp,
        documentTitle: documentTitle,
      });
      this.props.history.push("/");
    }
  }

  render() {
    // let role = localStorage.getItem("role");
    let role = localStorage.getItem("role");
    Logger.log(
      "MainApp render",
      this.props.selectedApp,
      localStorage.getItem("role"),
      role
    );
    let App = this.state.appType;
    document.title = this.state.documentTitle;
    return <App />;
  }
}

const mapStateToProps = (state) => {
  return {
    selectedApp: state.data_states.selectedApp,
    selectedRole: state.data_states.selectedRole,
    userProfileData: state.data_states.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboardApp: (selectedApp) =>
      dispatch({
        type: actionTypes.SET_DASHBOARD_APP,
        payload: selectedApp,
      }),
    updateSelectedRole: (selectedRole) =>
      dispatch({
        type: actionTypes.SET_SELECTED_ROLE,
        payload: selectedRole,
      }),
    updateUserProfileData: (profileData) =>
      dispatch({
        type: actionTypes.SET_USER_PROFILE_DATA,
        payload: profileData,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainApp));
