import * as actionTypes from "../actions";

const initialState = {
    isCampaignNameValid: true,
    isCampaignSlugValid: true,
    isCampaignCoupon: true,
    isCouponCodeCountValid: true,
    isCampaignTypeValid: true,
    isCampaignStartDateValid: true,
    isCampaignEndDateValid: true,
    isCampaignDateRangeValid: true,
    isCampaignDateNotOver: true,
};

const formValidationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CAMPAIGN_NAME_VALIDATION_STAT:
            return {
                ...state,
                isCampaignNameValid: action.payload,
            };

        case actionTypes.SET_CAMPAIGN_SLUG_VALIDATION_STAT:
            return {
                ...state,
                isCampaignSlugValid: action.payload,
            };

        case actionTypes.SET_CAMPAIGN_COUPON_COUNT_VALIDATION_STAT:
            return {
                ...state,
                isCouponCodeCountValid: action.payload,
            };
        case actionTypes.SET_CAMPAIGN_COUPON_TYPE_VALIDATION_STAT:
            return {
                ...state,
                isCampaignTypeValid: action.payload,
            };

        case actionTypes.SET_CAMPAIGN_START_DATE_VALIDATION_STAT:
            return {
                ...state,
                isCampaignStartDateValid: action.payload,
            };

        case actionTypes.SET_CAMPAIGN_END_DATE_VALIDATION_STAT:
            return {
                ...state,
                isCampaignEndDateValid: action.payload,
            };

        case actionTypes.SET_CAMPAIGN_DATE_RANGE_VALIDATION_STAT:
            return {
                ...state,
                isCampaignDateRangeValid: action.payload,
            };
        case actionTypes.SET_CAMPAIGN_DATE_NOT_OVER_STAT:
            return {
                ...state,
                isCampaignDateNotOver: action.payload,
            };

        default:
            return state;
    }
};

export default formValidationReducer;
